import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import * as moment from "moment";
import { BLOCKS } from "@contentful/rich-text-types";

function Post({ pathContext: { node } }) {
  const { title, subtitle, author, createdAt, image, slug, content } = node;
  return (
    <Layout>
      <SEO title={title} description={subtitle} />
      <div className="post-template">
        <article className="post">
          <header className="cover post-header">
            <div
              className="cover-bg"
              style={{
                backgroundImage: `url(http:${image.fluid.src})`
              }}
            ></div>
            <div className="cover-content">
              <div className="inner">
                <h1 className="post-title">{title}</h1>
                <div className="post-meta">
                  by{" "}
                  <span className="post-author">
                    <a href="/author/wenbo/">{author}</a>
                  </span>{" "}
                  on{" "}
                  <time className="published">
                    {moment(createdAt).format("DD/MM/YYYY")}
                  </time>{" "}
                </div>
                <a
                  href="#"
                  className="scroll-down square light icon-chevron-down"
                >
                  <span className="screen-reader-text">Scroll Down</span>
                </a>
              </div>
            </div>
          </header>
          <div className="post-content inner">
            {documentToReactComponents(content.json, {
              renderNode: {
                [BLOCKS.HEADING_5]: (node, children) => <pre>{children}</pre>
              }
            })}
          </div>
          <footer className="post-footer inner">
            <div className="share-post">
              <span>Share</span>
              <a
                className="icon-twitter square"
                target="_blank"
                href={`http://twitter.com/share?text=${title};url=http://wenbolovescode.com/posts/${slug}`}
              >
                <span className="screen-reader-text">Twitter</span>
              </a>
              <a
                className="icon-facebook square"
                target="_blank"
                href={`http://wwww.facebook.com/sharer.php?u=http://wenbolovescode.com/posts/${slug}`}
              >
                <span className="screen-reader-text">Facebook</span>
              </a>
            </div>
            {/* <div className="author-box">
            <img src="" alt="Wenbo L" className="author-avatar"></img>
            <h4 className="author-title">
              About <a href="/author/wenbo/">Wenbo L</a>
            </h4>
          </div> */}
          </footer>
        </article>
      </div>
    </Layout>
  );
}
export default Post;
